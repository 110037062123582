import type { MetaPropertyName, MetaPropertyProperty } from 'vue-meta'
import VueI18n from 'vue-i18n'

const siteName = 'Alao'
const email = 'support@alao.ch'
const twitterAccount = '@alao_ch'
const copyrights = 'Copyright alao GmbH.  All rights reserved.'

interface MetaTagsPayload {
  url: string,
  title: string,
  description: string,
  keywords?: string,
  langCode: string,
  imageUrl: string,
  imageWidth: string,
  imageHeight: string,
  isNoIndex?: boolean,
}

interface JSONLDPayload {
  title: string,
  description: string,
  imageUrl: string,
  url: string,
}

interface DCMetaTagsPayload {
  title: string,
  langCode: string,
  description: string,
}

const getOGMetaTags = ({
  url,
  title,
  description,
  langCode,
  imageUrl,
  imageWidth,
  imageHeight,
}: MetaTagsPayload): (MetaPropertyName|MetaPropertyProperty)[] => {
  const lang = `${langCode}_${langCode.toUpperCase()}`

  return [
    { hid: 'og:locale', property: 'og:locale', content: lang },
    { hid: 'og:type', property: 'og:type', content: 'object' },
    { hid: 'og:title', property: 'og:title', content: title },
    { hid: 'og:description', property: 'og:description', content: description },
    { hid: 'og:url', property: 'og:url', content: url },
    { hid: 'og:site_name', property: 'og:site_name', content: siteName },
    { hid: 'og:image', property: 'og:image', content: imageUrl },
    { hid: 'og:image:width', property: 'og:image:width', content: imageWidth },
    { hid: 'og:image:height', property: 'og:image:height', content: imageHeight },
    { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
    { hid: 'twitter:title', name: 'twitter:title', content: siteName },
    { hid: 'twitter:site', name: 'twitter:site', content: twitterAccount },
  ]
}

const getDCMetaTags = ({
  title,
  description,
  langCode,
}: DCMetaTagsPayload): MetaPropertyName[] => {
  return [
    { hid: 'DC.Publisher', name: 'DC.Publisher', content: siteName },
    { hid: 'DC.Publisher.Address', name: 'DC.Publisher.Address', content: email },
    { hid: 'DC.Creator', name: 'DC.Creator', content: siteName },
    { hid: 'DC.Type', name: 'DC.Type', content: 'text/html' },
    { hid: 'DC.Description', name: 'DC.Description', content: description, lang: langCode.toUpperCase(), 'xml:lang': langCode.toUpperCase() },
    { hid: 'DC.Language', name: 'DC.Language', content: langCode.toUpperCase() },
    { hid: 'DC.Rights', name: 'DC.Rights', content: copyrights },
    { hid: 'DC.title', name: 'DC.title', content: title },
    { hid: 'DC.title', name: 'DC.title', content: title },
  ]
}

const getGeoMetaTags = (): MetaPropertyName[] => {
  return [
    { hid: 'geo.region', name: 'geo.region', content: 'CH-ZH' },
    { hid: 'geo.placename', name: 'geo.placename', content: 'Z&uuml;rich' },
    { hid: 'geo.position', name: 'geo.position', content: '47.36428;8.55231' },
    { hid: 'ICBM', name: 'ICBM', content: '47.36428, 8.55231' },
  ]
}

export const getMetaTags = ({
  url,
  title,
  description,
  langCode,
  imageUrl,
  imageWidth,
  imageHeight,
}: MetaTagsPayload): (MetaPropertyName|MetaPropertyProperty)[] => {
  return [
    ...getOGMetaTags({ url, title, description, langCode, imageUrl, imageWidth, imageHeight }),
    ...getDCMetaTags({ title, description, langCode }),
    ...getGeoMetaTags(),
  ]
}

export const getJSONLDMeta = ({
  title,
  description,
  imageUrl,
  url,
}: JSONLDPayload) => {
  return {
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Webpage',
          name: title,
          description,
          url,
          image: {
            '@type': 'ImageObject',
            url: imageUrl,
            height: 630,
            width: 1200,
          },
          primaryImageOfPage: {
            '@type': 'ImageObject',
            url: imageUrl,
          },
        },
      },
    ],
  }
}

export const getSEOMeta = ({
  url,
  title,
  description,
  keywords = '',
  langCode,
  imageUrl,
  imageWidth,
  imageHeight,
  isNoIndex,
}: MetaTagsPayload) => {
  const metaInfo = {
    title,
    meta: [
      {
        hid: 'description', name: 'description', content: description,
      },
      {
        hid: 'keywords', name: 'keywords', content: keywords,
      },
      ...getMetaTags({
        url,
        title,
        description,
        langCode,
        imageUrl,
        imageWidth,
        imageHeight,
      }),
    ],
  }

  if (isNoIndex) {
    metaInfo.meta.push(
      {
        hid: 'robots', name: 'robots', content: 'noindex, nofollow',
      },
    )
  }

  return {
    ...metaInfo,
    ...getJSONLDMeta({
      title,
      description,
      imageUrl,
      url,
    }),
  }
}

interface DynamicSEOMetaTagsPayload {
  en: string,
  de: string,
  fr: string,
}

export const getDynamicSEOMetaTags = (currentLocale: VueI18n.Locale, { en: enPath, de: dePath, fr: frPath }: DynamicSEOMetaTagsPayload): any[] => {
  const canonicalLinks: Record<VueI18n.Locale, string> = {
    en: enPath,
    de: dePath,
    fr: frPath,
  }

  return [
    { hid: 'i18n-xd', rel: 'alternate', hreflang: 'x-default', href: dePath },
    { hid: 'i18n-alt-en-CH', rel: 'alternate', hreflang: 'en-CH', href: enPath },
    { hid: 'i18n-alt-de-CH', rel: 'alternate', hreflang: 'de-CH', href: dePath },
    { hid: 'i18n-alt-fr-CH', rel: 'alternate', hreflang: 'fr-CH', href: frPath },
  ]
}

interface ProviderPageSchemaOrgPayload {
  name: string,
  image: string,
  description: string,
  ratingValue: string,
  reviewCount: string,
}
